import { LatLng } from "leaflet";
import { Device, DeviceState, DeviceType } from "./device";

export class CVM extends Device {
    // CVMs are always ok
    getState(): DeviceState {
        return DeviceState.OK;
    }

    updateData(key: string, value: any): void {
        throw new Error("Method not implemented.");
    }
    /**
     * CVM devices do not have position on their own. 
     * 
     * They might be in a vehicle, which have a UBX.
     * This connection is not build into the dashboard (@21.10.24)
     * @returns undefined
     */
    getPosition(): LatLng | undefined {
        return undefined;
    }

    current: number = 0;
    power: number = 0;
    voltage: number = 0;

    constructor(
        device_id: string,
        object_id: string,
        params: {
            current?: number,
            power?: number,
            voltage?: number,

            lastUpdate: number
        }
    ) {
        super(
            device_id,
            DeviceType.cvm,
            object_id,
            Date.now(),
        );
        if (params.current) this.current = params.current;
        if (params.power) this.power = params.power;
        if (params.voltage) this.voltage = params.voltage;
    }


}