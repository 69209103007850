import { sendWSRequest } from "../helper/websocket";
import { DashboardState, DashboardStateAction } from "./dashboardReducer";

export class QueryNotificationsAction implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        if (s.websocket === null) {
            return s;
        }
        sendWSRequest(s.websocket, 'notifications', { 'user_id': s.userID });

        return s;
    }
}

// [ ] TODO(implmement this function)
// [ ] TODO(test this function)
export class TestNotificationAction implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        if (s.websocket === null) {
            return s;
        }
        sendWSRequest(s.websocket, 'test_notification', { 'notification_id': this.notificationID })
        return s;
    };

    constructor(notificationID: string) {
        this.notificationID = notificationID;
    }

    notificationID: string;
}

// [ ] TODO(implement this function)
// [ ] TODO(test this function)
// export class UpdateNotificationAction implements DashboardStateAction {
//     type: DashboardStateActionType = DashboardStateActionType.UpdateNotification;
//     updateState: (state: DashboardState) => DashboardState = (s) => s;
//     notification: Notification;
//     constructor(notification: Notification) {
//         this.notification = notification;
//     }
// }