export abstract class Notification {
    databaseID: string;


    constructor(props: {
        databaseID: string,
    }) {
        this.databaseID = props.databaseID;
    }
}

// export class EmptyNotification extends Notification {
//     constructor() {
//         super(
//             {
//                 databaseID: 'emptyDatabaseID',
//                 notificationID: 'emptyNotificationID',
//                 username: 'emptyUsername',
//             }
//         );
//     }
// }

export class TelegramNotification extends Notification {
    channelID: string;
    apiToken: string;

    constructor(props: {
        databaseID: string

        apiToken: string,
        channelID: string,
    }) {
        super({ databaseID: props.databaseID });
        this.apiToken = props.apiToken;
        this.channelID = props.channelID;
    }
}

export function BuildNotification(data: any): (Notification | null) {
    switch (data['type']) {
        case 'telegram':
            return new TelegramNotification({
                apiToken: data['api_token'],
                channelID: data['channel_id'],
                databaseID: data['object_id'],
            });
        default:
            return null;
    }
}