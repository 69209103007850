import { LatLng } from "leaflet";
import { Device, DeviceState, DeviceType } from "./device";

export enum SirenenState { ok, not_ok, unknown };

export function SirenenStateToString(sirenenState: SirenenState): string {
    switch (sirenenState) {
        case SirenenState.ok:
            return 'OK';
        case SirenenState.not_ok:
            return 'Fehler';
        case SirenenState.unknown:
            return 'Unbekannt';
    }
}

export class Sirene extends Device {
    getState(): DeviceState {
        if (
            this.batteryState === SirenenState.not_ok &&
            this.technicalState === SirenenState.not_ok) {
            return DeviceState.ALARM;
        }
        if (this.batteryState === SirenenState.not_ok) {
            return DeviceState.WARNING;
        }
        if (this.technicalState === SirenenState.not_ok) {
            return DeviceState.WARNING;
        }
        return DeviceState.OK;
    }
    getPosition(): LatLng | undefined {
        return this.position;
    }

    updateData(key: string, value: any): void {
        throw new Error("Method not implemented.");
    }

    technicalState: SirenenState = SirenenState.unknown;
    batteryState: SirenenState = SirenenState.unknown;
    position: LatLng | undefined;

    constructor(data: any) {
        super(data['device_id'], DeviceType.sirene, data['object_id'], data['last_update']);
        this.technicalState = data['technical_state'] ?? SirenenState.ok;
        this.batteryState = data['battery_state'] ?? SirenenState.not_ok;
        this.position = data['position'];
    }

}