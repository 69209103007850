import { LatLng } from "leaflet";
import { Device, DeviceState, DeviceType } from "./device";

export class NBX extends Device {
    getState(): DeviceState {
        if (this.VDD && (
            this.VDD < 12.0 || this.VDD > 26.0)) {
            return DeviceState.WARNING;
        }
        return DeviceState.OK;

    }

    updateData(key: string, value: any): void {
        switch (key) {
            case 'vdd':
                this.VDD = value;
                break;

            default:
                break;
        }
    }

    // nxb specific information
    VDD: number | undefined;
    Vin0: number | undefined;
    Vin1: number | undefined;
    Vin2: number | undefined;
    Vin3: number | undefined;
    Din2: boolean | undefined;
    Din3: boolean | undefined;
    Outd7: boolean | undefined;

    FugState: number | undefined;
    CregState: number | undefined;
    TetraCellID: number | undefined;
    TetraCellState: string | undefined;
    TetraRSSI: number | undefined;
    TetraGroup: string | undefined;
    TetraISSI: string | undefined;
    TETRAOpta: string | undefined;

    VehicleDistance: number | undefined;
    VehicleFuelLevel: number | undefined;
    VehicleFuelTotal: number | undefined;
    NavState: number | undefined;

    ParticleVersion: string | undefined;
    ParticleDate: string | undefined;
    ParticleFirmware: string | undefined;
    FreeMem: number | undefined;
    Uptime: string | undefined;
    GsmData: string | undefined;

    // Location Data
    // Position
    PosLatitude: number | undefined;
    PosLongitude: number | undefined;
    PosHeight: number | undefined;
    PosTimestamp: number | undefined;
    PosAccuracy: number | undefined;
    GPSType: number | undefined;

    // Speed
    SpeedEast: number | undefined;
    SpeedNorth: number | undefined;

    constructor(data: any) {
        super(
            data['device_id'],
            DeviceType.nbx,
            data['object_id'],
            Date.now(),
        );
        this.VDD = data['vdd'];
        this.Vin0 = data['vin_0'];
        this.Vin1 = data['vin_1'];
        this.Vin2 = data['vin_2'];
        this.Vin3 = data['vin_3'];
        this.Din2 = data['din_2'];
        this.Din3 = data['din_3'];
        this.Outd7 = data['outd_7'];
        this.FugState = data['fug_state'];
        this.CregState = data['creg_state'];
        this.TetraCellID = data['tetra_cell_id'];
        this.TetraCellState = data['tetra_cell_state'];
        this.TetraRSSI = data['tetra_rssi'];
        this.TetraGroup = data['tetra_group'];
        this.TetraISSI = data['tetra_issi'];
        this.VehicleDistance = data['vehicle_distance'];
        this.VehicleFuelLevel = data['vehicle_fuel_level'];
        this.VehicleFuelTotal = data['vehicle_fuel_total'];
        this.NavState = data['nav_state'];
        this.ParticleVersion = data['particle_version'];
        this.ParticleDate = data['particle_date'];
        this.ParticleFirmware = data['particle_firmware'];
        this.FreeMem = data['free_mem'];
        this.Uptime = data['uptime'];
        this.GsmData = data['gsm_data'];
        this.TETRAOpta = data['tetra_opta'];
        this.PosLatitude = data['pos_latitude'];
        this.PosLongitude = data['pos_longitude'];
        this.PosHeight = data['pos_height'];
        this.PosTimestamp = data['pos_timestamp'];
        this.PosAccuracy = data['pos_accuracy'];
        this.GPSType = data['gps_type'];
        this.SpeedEast = data['speed_east'];
        this.SpeedNorth = data['speed_north'];
    }

    // // HEADER
    // // [ ] WTF is UBXVersion doing in the nbx data???
    // UBXVersion: string = '';
    // Garmin: string = '';

    // // Voltages
    // voltageProperties: [
    //     DeviceProperty<number>,
    //     DeviceProperty<number>,
    //     DeviceProperty<number>,
    //     DeviceProperty<number>,
    // ] = [
    //         new NumberDeviceProperty('VDD'),
    //         new NumberDeviceProperty('VIN0'),
    //         new NumberDeviceProperty('VIN1'),
    //         new NumberDeviceProperty('VIN2'),
    //     ]

    // // Data
    // DIN2: number = 0;
    // DIN3: number = 0;

    // // Particle Information

    // // FUG
    // GSSI_DMO: number = 0;
    // GSSI_TMO: number = 0;
    // RSSI: number = -99;
    // OPTA: string = '';
    // CellState: string = '';

    // // Position
    // latitude?: number;
    // longitude?: number;

    // constructor(
    //     name: string,
    //     id: string,
    //     params: {
    //         // base device
    //         // deviceState?: DeviceState,
    //         lastUpdate?: number,

    //         // Header
    //         UBXVersion?: string,
    //         Garmin?: string,

    //         // Voltages
    //         VDD?: number,
    //         VIN0?: number,
    //         VIN1?: number,
    //         VIN2?: number,

    //         // Data
    //         DIN2?: number,
    //         DIN3?: number,

    //         // Particle Information


    //         // FuG
    //         GSSI_DMO?: number,
    //         GSSI_TMO?: number,
    //         RSSI?: number,
    //         OPTA?: string,
    //         CellState?: string,

    //         // Position
    //         latitude?: number,
    //         longitude?: number,

    //     }
    // ) {
    //     super(name, DeviceType.nbx, id, { lastUpdate: params.lastUpdate },);


    //     // optionals

    //     // Header
    //     if (params.UBXVersion) this.UBXVersion = params.UBXVersion;
    //     if (params.Garmin) this.Garmin = params.Garmin;

    //     // Voltages
    //     this.voltageProperties[0].setValue(params.VDD);
    //     this.voltageProperties[1].setValue(params.VIN0);
    //     this.voltageProperties[2].setValue(params.VIN1);
    //     this.voltageProperties[3].setValue(params.VIN2);

    //     // Data
    //     if (params.DIN2) this.DIN2 = params.DIN2;
    //     if (params.DIN2) this.DIN2 = params.DIN2;

    //     // Particle

    //     // FuG
    //     if (params.GSSI_DMO) this.GSSI_DMO = params.GSSI_DMO;
    //     if (params.GSSI_TMO) this.GSSI_TMO = params.GSSI_TMO;
    //     if (params.RSSI) this.RSSI = params.RSSI;
    //     if (params.OPTA) this.OPTA = params.OPTA;
    //     if (params.CellState) this.CellState = params.CellState;

    //     if (params.latitude) this.latitude = params.latitude;
    //     if (params.longitude) this.longitude = params.longitude;
    // }

    // update() { }

    // getState(): DeviceState {
    //     let state = DeviceState.OK;

    //     for (let i = 0; i < this.voltageProperties.length; i++) {
    //         let newState = this.voltageProperties.at(i)!.getDeviceState();
    //         if (newState < state) state = newState;
    //     }
    //     // this.voltageProperties.forEach(prop => {
    //     //     log_text(`prop: ${prop.name} -> ${prop.getDeviceState()}`, DebugLevels.ERROR)
    //     //     let newState = prop.getDeviceState();
    //     //     if (newState < state) state = newState;
    //     // });
    //     // if (this.voltageProperties[1].value <= 12.0) return DeviceState.WARNING;
    //     return state;
    // }

    getPosition(): LatLng | undefined {
        if (!this.PosLatitude || !this.PosLongitude) return undefined;
        return new LatLng(this.PosLatitude, this.PosLongitude);
    }
}