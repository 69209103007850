import { LatLng } from "leaflet";
import { BoolDeviceProperty, DeviceProperty, NumberDeviceProperty, StringDeviceProperty } from "../deviceProperty";
import { Device, DeviceState, DeviceType } from "./device";

namespace UBX {
    export type digitalIOMapKeys = 'din1' | 'din2' | 'dout1' | 'dout2';
    export type gmsMapKeys = 'gsm_carrier' | 'gsm_signal_strength' | 'gsm_iccid';
    export type licenceMapKeys = 'bluetooth' | 'data' | 'navigation';
    export type fugMapKeys =
        'fug_state' | 'fug_mode' | 'fug_vol' | 'folder_name' | 'fug_manufacturer' |
        'c_reg_state' | 'cell_id' | 'gssi_name' | 'tetra_gssi' | 'issi' | 'rssi' |
        'tetra_status' | 'tetra_net' | 'tetra_opta';
}

export class UBX extends Device {

    getState(): DeviceState {
        if (this.voltageProperties[0].value < 12.0 || this.voltageProperties[0].value > 26.0) {
            return DeviceState.WARNING;
        }
        return DeviceState.OK;
    }

    updateData(key: string, value: any): void {
        switch (key) {
            case 'vdd':
                this.voltageProperties[0] = value;
                break;
            case 'vin_1':
                this.voltageProperties[1] = value;
                break;
            case 'vin_2':
                this.voltageProperties[2] = value;
                break;

            case 'din_1':
                this.digitalIO.set("din1", value);
                break;
            case 'din_2':
                this.digitalIO.set("din2", value);
                break;
            case 'dout_1':
                this.digitalIO.set("dout1", value);
                return;
            case 'dout_2':
                this.digitalIO.set("dout2", value);
                return;

            case 'fug_state':
                this.fug.set("fug_state", value);
                break;
            case 'fug_mode':
                this.fug.set("fug_mode", value);
                break;
            case 'fug_vol':
                this.fug.set("fug_vol", value);
                break;
            case 'folder_name':
                this.fug.set("folder_name", value);
                break;
            case 'fug_manufacturer':
                this.fug.set("fug_manufacturer", value)
                break;
            case 'c_reg_state':
                this.fug.set("c_reg_state", value);
                break;
            case 'cell_id':
                this.fug.set("cell_id", value);
                break;
            case 'gssi_name':
                this.fug.set("gssi_name", value);
                break;
            case 'tetra_gssi':
                this.fug.set("tetra_gssi", value);
                break;
            case 'issi':
                this.fug.set("issi", value);
                break;
            case 'rssi':
                this.fug.set("rssi", value);
                break;
            case 'tetra_status':
                this.fug.set("tetra_status", value);
                break;
            case 'tetra_net':
                this.fug.set("tetra_net", value);
                break;
            case 'tetra_opta':
                this.fug.set("tetra_opta", value);
                break;

            case 'gsm_carrier':
                this.gsm.set("gsm_carrier", value);
                break;
            case 'gsm_iccid':
                this.gsm.set("gsm_iccid", value);
                break;
            case 'gsm_signal_strength':
                this.gsm.set("gsm_signal_strength", value);
                break;

            case 'licence_bluetooth':
                this.licences.set("bluetooth", value)
                break;
            case 'licence_data':
                this.licences.set("data", value)
                break;
            case 'licence_navigation':
                this.licences.set("navigation", value)
                break;
            default:
                console.log(`could not set value ${value} for key ${key}`)
                break;
        }
    }
    getPosition(): LatLng | undefined {
        return undefined;
        // if (!this.PosLatitude || !this.PosLongitude) return undefined;
        // return new LatLng(this.PosLatitude, this.PosLongitude);
    }

    // Common
    softwareVersion: string;

    // Voltages
    voltageProperties: [
        DeviceProperty<number>,
        DeviceProperty<number>,
        DeviceProperty<number>,
    ] = [
            new NumberDeviceProperty('VDD'),
            new NumberDeviceProperty('VIN1'),
            new NumberDeviceProperty('VIN2'),
        ]

    // Digital IO
    digitalIO: Map<UBX.digitalIOMapKeys, DeviceProperty<boolean>> = new Map([
        ['din1', new BoolDeviceProperty('din1')],
        ['din2', new BoolDeviceProperty('din2')],
        ['dout1', new BoolDeviceProperty('dout1')],
        ['dout2', new BoolDeviceProperty('dout2')],
    ]);

    // FuG
    fug: Map<UBX.fugMapKeys, DeviceProperty<any>> =
        new Map<UBX.fugMapKeys, DeviceProperty<any>>(
            [
                ['fug_state', new NumberDeviceProperty('fug_state')],
                ['fug_mode', new StringDeviceProperty('fug_mode')],
                ['fug_vol', new NumberDeviceProperty('fug_vol')],
                ['folder_name', new StringDeviceProperty('folder_name')],
                ['fug_manufacturer', new NumberDeviceProperty('fug_manufacturer')],

                ['c_reg_state', new NumberDeviceProperty('c_reg_state')],
                ['cell_id', new StringDeviceProperty('cell_id')],
                ['gssi_name', new StringDeviceProperty('gssi_name')],
                ['tetra_gssi', new StringDeviceProperty('tetra_gssi')],
                ['issi', new StringDeviceProperty('issi')],
                ['rssi', new NumberDeviceProperty('rssi')],

                ['tetra_status', new NumberDeviceProperty('tetra_status')],
                ['tetra_net', new StringDeviceProperty('tetra_net')],
                ['tetra_opta', new StringDeviceProperty('tetra_opta')],
            ]
        );

    // Vehicle

    // GSM
    gsm: Map<UBX.gmsMapKeys, DeviceProperty<number | string>> =
        new Map<UBX.gmsMapKeys, DeviceProperty<number | string>>(
            [
                ['gsm_carrier', new StringDeviceProperty('gsm_carrier')],
                ['gsm_iccid', new StringDeviceProperty('gsm_iccid')],
                ['gsm_signal_strength', new NumberDeviceProperty('gsm_signal_strength')],
            ]
        )

    // Licences
    licences: Map<UBX.licenceMapKeys, DeviceProperty<boolean>> = new Map(
        [
            ['bluetooth', new BoolDeviceProperty('Bluetooth')],
            ['data', new BoolDeviceProperty('Data')],
            ['navigation', new BoolDeviceProperty('Navigation')],
        ]
    );


    // name: string,
    // id: string,
    // params: {
    //     softwareVersion?: string,
    //     // deviceState?: DeviceState,
    //     lastUpdate?: number,

    //     // voltages
    //     VDD?: number,
    //     VIN0?: number,
    //     VIN1?: number,

    //     // digital IO
    //     din_1?: boolean,
    //     din_2?: boolean,
    //     dout_1?: boolean,
    //     dout_2?: boolean,

    //     // FuG
    //     fug_state?: number,
    //     fug_mode?: string,
    //     fug_vol?: number,
    //     folder_name?: string,
    //     fug_manufacturer?: number,

    //     c_reg_state?: number,
    //     cell_id?: string,
    //     gssi_name?: string,
    //     tetra_gssi?: string,
    //     issi?: string,
    //     rssi?: number,
    //     tetra_status?: string,

    //     tetra_net?: string,
    //     tetra_opta?: string,

    //     // GSM
    //     gsm_carrier?: string;
    //     gsm_signal_strength?: string;
    //     gsm_iccid?: string;

    //     // licences
    //     bluetoothLicence?: boolean,
    //     dataLicence?: boolean,
    //     navigationLicence?: boolean,
    // }
    constructor(
        data: any,
    ) {
        // TODO(flo,BUG,PRIO1) get date of last update
        super(data['device_id'],
            DeviceType.ubx,
            data['object_id'],
            Date.now(),

        );
        // super(name, DeviceType.ubx, id, Date.now());

        this.softwareVersion = data['softwareVersion'] ?? 'unbekannt';

        // Voltages
        this.voltageProperties[0].setValue(data['vdd']);
        this.voltageProperties[1].setValue(data['vin_0']);
        this.voltageProperties[2].setValue(data['vin_1']);

        // DigitalIO
        this.digitalIO.get("din1")?.setValue(data['din_1']);
        this.digitalIO.get("din2")?.setValue(data['din_2']);
        this.digitalIO.get("dout1")?.setValue(data['dout_1']);
        this.digitalIO.get("dout2")?.setValue(data['dout_2']);

        // FuG
        this.fug.get('fug_state')?.setValue(data['fug_state'])
        this.fug.get('fug_mode')?.setValue(data['fug_mode'])
        this.fug.get('fug_vol')?.setValue(data['fug_vol'])
        this.fug.get('folder_name')?.setValue(data['folder_name'])
        this.fug.get('fug_manufacturer')?.setValue(data['fug_manufacturer'])

        this.fug.get('c_reg_state')?.setValue(data['c_reg_state'])
        this.fug.get('cell_id')?.setValue(data['cell_id'])
        this.fug.get('gssi_name')?.setValue(data['gssi_name'])
        this.fug.get('tetra_gssi')?.setValue(data['tetra_gssi'])
        this.fug.get('issi')?.setValue(data['issi'])
        this.fug.get('rssi')?.setValue(data['rssi'])

        this.fug.get('tetra_status')?.setValue(data['tetra_status'])
        this.fug.get('tetra_net')?.setValue(data['tetra_net'])
        this.fug.get('tetra_opta')?.setValue(data['tetra_opta'])

        // Vehicle

        // GSM
        this.gsm.get('gsm_carrier')?.setValue(data['gsm_carrier']);
        this.gsm.get('gsm_iccid')?.setValue(data['gsm_iccid']);
        this.gsm.get('gsm_signal_strength')?.setValue(data['gsm_signal_strength']);

        // Licences
        this.licences.get('bluetooth')?.setValue(data['licence_bluetooth']);
        this.licences.get('data')?.setValue(data['licence_data']);
        this.licences.get('navigation')?.setValue(data['licence_navigation']);
    }

    // update this device with new data
    update() { }

    // getState(): DeviceState {
    //     let state = DeviceState.OK;

    //     for (let i = 0; i < this.voltageProperties.length; i++) {
    //         // assert(i < this.voltageProperties.length);
    //         let newState = this.voltageProperties.at(i)!.getDeviceState();
    //         if (newState < state) state = newState;
    //     }

    //     // if (this.voltageProperties[1].value <= 12.0) return DeviceState.WARNING;
    //     return state;
    // }
    // getState(): DeviceState {
    //     return DeviceState.NO_STATE;
    // }
}