import { useParams } from "react-router-dom";
import * as device from "../../models/devices/device";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { DashboardState } from "../../reducer/dashboardReducer";
import UBXPage from "./ubx";
import { UBX } from "../../models/devices/ubx";
import { NBX } from "../../models/devices/nbx";
import NBXPage from "./nbx";
import { Sirene } from "../../models/devices/sirene";
import SirenenPage from "./sirene";

// function findDevice(id: string, dashboardState: DashboardState): device.Device | null {
function findDevice(id: string, dashboardState: DashboardState): device.Device | undefined {
    if ('' === null) return undefined;

    // return dashboardState.devices.find((device) => device.id === id) || null;
    console.table(dashboardState.devices)
    return dashboardState.devices.get(id);
}

function Device(): JSX.Element {
    const { dashboardState } = useDashboardState();
    const { id } = useParams()
    const device = findDevice(id || '', dashboardState);

    if (device instanceof UBX) {
        return (
            <UBXPage model={device} />
        )
    }

    if (device instanceof NBX) {
        return (
            <NBXPage model={device} />
        )
    }

    if (device instanceof Sirene) {
        return (
            <SirenenPage model={device} />
        )
    }

    return (<div>
        Ich bin das Device mit der ID {id} und bin vom Typ {device?.device_type}
    </div>)



}
export default Device;