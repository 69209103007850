import { icon, } from 'leaflet';
import pkw_grey from '../../assets/pkw_gr.png';
import sirene_grey from '../../assets/sirene_gr.png';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Device, DeviceState, DeviceType, deviceTypToString } from '../../models/devices/device';

export function CreateMarkerForDevice(device: Device, idx: number, clickable: boolean = true): React.JSX.Element {
    let iconURL: string
    switch (device.device_type) {
        case DeviceType.sirene:
            iconURL = sirene_grey;
            break;
        default:
            iconURL = pkw_grey;
    }
    let _className: string;
    switch (device.getState()) {
        case DeviceState.OK:
            _className = 'icon-green';
            break;
        case DeviceState.WARNING:
            _className = 'icon-orange';
            break;
        case DeviceState.ALARM:
            _className = 'icon-red';
            break;
        default:
            _className = '';
    }
    return <Marker key={idx} position={device.getPosition()!}
        icon={
            icon({
                iconUrl: iconURL,
                shadowUrl: iconURL,
                iconAnchor: [10, 41],
                popupAnchor: [2, -41],
                className: _className,
            })
        }>
        <Popup key={idx}>
            {
                clickable
                    ?
                    <Link style={{ padding: '8px', fontSize: '24px' }} to={`/dashboard/device/${device.object_id}`}>{deviceTypToString(device.device_type)}:<br />{device.device_id}</Link>
                    :
                    <div style={{ padding: '8px', fontSize: '24px' }}>
                        {deviceTypToString(device.device_type)}:<br />{device.device_id}
                    </div>
            }
        </Popup>
    </Marker>
}