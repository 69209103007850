import { CVM } from "./cvm";
import { Device, DeviceType } from "./device";
import { NBX } from "./nbx";
import { Sirene } from "./sirene";
import { UBX } from "./ubx";

export default function BuildDevice(deviceType: DeviceType, data: any): Device | null {
    // console.log('data: ', data)
    switch (deviceType) {
        case DeviceType.nbx:
            return new NBX(data);
        case DeviceType.ubx:
            return new UBX(data);
        case DeviceType.sirene:
            return new Sirene(data);
        case DeviceType.cvm:
            return new CVM(data['device_name'], data['device_id'], {
                current: data['current'] as number,
                voltage: data['voltage'] as number,
                power: data['power'] as number,
                lastUpdate: Date.now()
            })
        case DeviceType.unknown:
            console.log('wtf??????????')
            return null
    }
}