import { MapContainer, TileLayer, useMap } from "react-leaflet";

import { useEffect, useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { log_text } from "../../log";
import { CreateMarkerForDevice } from "./map_marker";
import { Device } from "../../models/devices/device";
import { LatLng } from "leaflet";

const Recenter = (props: { position: LatLng }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(props.position);
    }, [props.position, map]);
    return null;
}

// [ ] TODO(flo, get deviceType and use the correct icon)
export function SingleDeviceMap(props: { device: Device }) {
    const [open, setOpen] = useState<boolean>(true);
    const device = props.device;

    log_text(device);

    if (device.getPosition() === undefined)
        return (
            <h1>Keine GPS-Daten verf&uuml;gbar</h1>
        )

    // let position: [number, number] = [props.Device.latitude, props.Device.longitude];

    return (
        <>
            <h2>
                Karte
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <MapContainer style={{ width: '100%', height: '500px' }} center={device.getPosition()} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        CreateMarkerForDevice(device, 0, false)
                    }
                    <Recenter position={device.getPosition()!} />
                </MapContainer>
            </Collapse>
        </>
    )
}