import { useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useReducer } from 'react';
import { InitialDashboardState, reducer } from './reducer/dashboardReducer';
import { DashboardStateContext } from './contexts/dashboardStateContext';

import "@fontsource/roboto";
import { DebugContext } from './contexts/debugContext';

function App() {
  // overall app state
  const [dashboardState, dispatch] = useReducer(reducer, InitialDashboardState);
  const DEBUG: boolean = false;

  // navigation and routing
  const navigate = useNavigate();

  // TODO(flo,BUG,PRIO1) the user should return to /login, if the websocket is set to null, but this does not work
  useEffect(() => {
    if (dashboardState.websocket == null) {
      navigate('/login');
    }
  }, [dashboardState.websocket, navigate])

  useEffect(() => {
    if (dashboardState.organisations.length === 1) {
      navigate('/dashboard/overview')
      // dispatch
    }
    if (dashboardState.organisations.length > 1) {
      navigate('/selectOrganisation')
    }
  }, [dashboardState.organisations, navigate]);

  return (
    <DashboardStateContext.Provider value={{ dashboardState, dispatch }}>
      <DebugContext.Provider value={{ debug: DEBUG, setDebug: () => { } }}>
        <div className="App">
          <Outlet />
        </div>
      </DebugContext.Provider>
    </DashboardStateContext.Provider >
  );
}

export default App;
