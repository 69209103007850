import { useNavigate, useParams } from "react-router"
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { TelegramNotification } from "../../models/notifications";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { DebugLevels, log_table, log_text } from "../../log";
import { CollapsableEditableTable } from "../../widgets/dashboard/collapsable_editable_table";
import { useState } from "react";

export function NotificationPage(): JSX.Element {
    const { databaseID } = useParams();
    const { dashboardState } = useDashboardState();
    // const notification = dashboardState.notifications.find((n) => n.notificationID === notificationID);
    const notification = dashboardState.notifications.find((n) => n.databaseID === databaseID);

    log_text(`databaseID: ${databaseID}, notification: ${notification}`, DebugLevels.ERROR);
    log_table(dashboardState.notifications, DebugLevels.ERROR);

    if (notification === undefined) {
        return (
            <div className="dashboard-body">
                <h1>Irgendwas lief schief mit der Notification</h1>
            </div >
        )
    }

    if (notification instanceof TelegramNotification) {
        return TelegramNotificationWidget(notification);
    }

    return (
        <div className="dashboard-body">
            <h1>Unbekannter Typ von Benachrichtigung</h1>
        </div >
    )
}

// function updateTelegramNotification(dispatch: React.Dispatch<DashboardStateAction>, oldNotification: TelegramNotification, notificationID: string, apiToken: string, channelID: string) {
// dispatch(new UpdateNotificationAction(new TelegramNotification({
//     apiToken: apiToken,
//     channelID: channelID,
//     databaseID: oldNotification.databaseID,
//     notificationID: notificationID,
//     username: oldNotification.username,
// })))
// }

function TelegramNotificationWidget(notification: TelegramNotification): JSX.Element {
    const navigate = useNavigate();
    // const { dispatch } = useDashboardState();
    // const [notificationID, setNotificationID] = useState<string>(notification.notificationID);
    const [apiToken, setApiToken] = useState<string>(notification.apiToken);
    const [channelID, setChannelID] = useState<string>(notification.channelID);
    return (
        <div className="dashboard-body">
            <h1> <IconButton onClick={() => navigate(`/dashboard/notifications`)}><ArrowBack /></IconButton> Telegram-Benachrichtigung</h1>
            <CollapsableEditableTable
                title="Einstellungen"
                details={[
                    // { label: "E-Mail", value: email, setValue: setEmail },
                    // { label: "Passwort", hidable: true, value: password, setValue: setPassword, placeholder: "Passwort nicht ändern" },
                    // { label: "NotificationID", value: notificationID, setValue: setNotificationID },
                    { label: "ChannelID", value: channelID, setValue: setChannelID },
                    { label: "API-Token", value: apiToken, setValue: setApiToken },

                ]}
            />
            {/* <p>ID: {notifcation.notificationID}</p>
            <p>ChannelID: {notifcation.channelID}</p> */}

            {/* <FloatingButton label="Einstellungen speichern" onclick={() => { navigate(`/dashboard/notifications`) }} /> */}
            {/* <FloatingButton label="Einstellungen speichern" onclick={() => updateTelegramNotification(dispatch, notification, notificationID, apiToken, channelID)} /> */}
        </div >
    )
}