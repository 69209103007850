import { Card, CardContent } from "@mui/material";
import { NotificationTable } from "../../widgets/dashboard/notificaiton_table";
import { TelegramNotification } from "../../models/notifications";

export function TelegramNotificationWidget(props: { notification: TelegramNotification }): JSX.Element {
    return (
        <Card elevation={8}>
            {/* <CardHeader title="Telegram-Notification" /> */}
            <CardContent>
                <NotificationTable
                    type="Telegram"
                    databaseID={props.notification.databaseID}
                    details={[
                        ["ChannelID", props.notification.channelID],
                        ["API Token", props.notification.apiToken],
                    ]} />
            </CardContent>
        </Card>
    )
}