import { CollapsableTable } from "../../widgets/dashboard/collapsable_table";
import { VerticalSpacer } from "../../widgets/spacer";
import { isMoreThan10DaysOld } from "../../helper/date";
import { CollapsableDeviceTable } from "../../widgets/dashboard/collapsable_device_table";
// import { BoolDeviceProperty, NumberDeviceProperty, StringDeviceProperty } from "../../models/deviceProperty";
import { StringDeviceProperty } from "../../models/deviceProperty";
import { Sirene, SirenenStateToString } from "../../models/devices/sirene";
import { SingleDeviceMap } from "../../widgets/dashboard/device_map";
import { useEffect } from "react";

interface SirenenWidgetProps {
    model: Sirene;
}

export default function SirenenPage(props: SirenenWidgetProps) {
    // const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    useEffect(() => {
        document.querySelector('.dashboard-page-main-content')?.scrollTo(0, 0);
    }, [])

    const lastUpdateString: string =
        isMoreThan10DaysOld(props.model.lastUpdate)
            ? 'nie'
            : new Date(props.model.lastUpdate!).toLocaleString()

    return (
        <div className="dashboard-body">

            <h1>
                {/* {dashboardState.userrole === UserRole.admin &&
                    <IconButton onClick={() => { setModalIsOpen(true) }}><Build /></IconButton>
                } */}
                Name: {props.model.device_id}
            </h1>

            <CollapsableTable title="Sirene" details={[
                ['Letztes Update', lastUpdateString],
            ]} />


            <VerticalSpacer height={16} />
            <CollapsableDeviceTable title="Spannungen" details={
                [
                    new StringDeviceProperty('Technischer Status', SirenenStateToString(props.model.technicalState)),
                    new StringDeviceProperty('Batterie Status', SirenenStateToString(props.model.batteryState)),
                    // props.model.voltageProperties
                ]
            } />
            {/* <CollapsableDeviceTable title="Spannungen" details={
                [
                    new NumberDeviceProperty('VDD', props.model.VDD),
                    new NumberDeviceProperty('Vin0', props.model.Vin0),
                    new NumberDeviceProperty('Vin1', props.model.Vin1),
                    new NumberDeviceProperty('Vin2', props.model.Vin2),
                    new NumberDeviceProperty('Vin3', props.model.Vin3),
                    // props.model.voltageProperties
                ]
            } /> */}

            <VerticalSpacer height={16} />

            {/* <CollapsableDeviceTable title="Digitale Ein- und Ausg&auml;nge" details={
                [
                    new BoolDeviceProperty('Digital In 2', props.model.Din2),
                    new BoolDeviceProperty('Digital In 3', props.model.Din3),
                    new BoolDeviceProperty('Digital Out 7', props.model.Outd7),
                ]
            } /> */}

            <VerticalSpacer height={16} />

            {/* <CollapsableDeviceTable title="Funkgerät" details={[
                new StringDeviceProperty('TETRA OPTA', props.model.TETRAOpta),
                new NumberDeviceProperty('FuG-State', props.model.FugState),
                new NumberDeviceProperty('CregState', props.model.CregState),
                new NumberDeviceProperty('TetraCellID', props.model.TetraCellID),
                new StringDeviceProperty('TetraCellState', props.model.TetraCellState),
                new NumberDeviceProperty('TetraRSSI', props.model.TetraRSSI),
                new StringDeviceProperty('TetraGroup ', props.model.TetraGroup),
                new StringDeviceProperty('TetraISSI ', props.model.TetraISSI),
            ]} /> */}

            <VerticalSpacer height={16} />

            {/* <CollapsableDeviceTable title="Position" details={[
                new NumberDeviceProperty('Latitude', props.model.PosLatitude),
                new NumberDeviceProperty('Longitude', props.model.PosLongitude),
                new NumberDeviceProperty('Height', props.model.PosHeight),
                new StringDeviceProperty('Pos-Timestamp', props.model.PosTimestamp?.toString() ?? 'never'),
                new NumberDeviceProperty('Pos-Accuracy', props.model.PosAccuracy),
            ]} /> */}

            <VerticalSpacer height={16} />

            <SingleDeviceMap
                device={props.model}
            />

            <VerticalSpacer height={16} />

            {/* // [ ] use conditional widget */}
            {/* {createSendCommand(dashboardState)} */}

            {
                // modalIsOpen &&
                // <ModalTextDialog
                //     isOpen={modalIsOpen}
                //     setIsOpen={setModalIsOpen}
                //     initialText={props.model.name}
                // // onSubmit={(v) => { dispatch(new UpdateDeviceNameAction(props.model.id, v)) }}
                // />
            }
        </div>
    )
}