import React, { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { Device } from "../../models/devices/device";

import { LatLng } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
// import iconURL from "leaflet/dist/images/marker-icon.png";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { CreateMarkerForDevice } from "./map_marker";

export interface MultiDeviceMapProps {
    Devices: Map<String, Device>
}

export function MultiDeviceMap(props: MultiDeviceMapProps) {
    const [open, setOpen] = useState<boolean>(true);
    // const [homePosition, setHomePosition] = useState<LatLng>(new LatLng(50.1431, 9.1438));
    const [homePosition, setHomePosition] = useState<LatLng | undefined>();

    // only necessary for multiple devices
    // console.log(props.Devices.entries())
    if (props.Devices.size === 0) return (
        <h1>Kein Ger&auml;t gefunden</h1>
    );

    if (Array.from(props.Devices).every(([, device]: [any, Device]) => device.getPosition() === undefined)) {
        return <h1>Keine Positionsdaten vorhanden</h1>
    }

    return (
        <>
            <h2>
                Karte
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <MapContainer style={{ width: '100%', height: '500px' }} center={homePosition ?? new LatLng(50.1431, 9.1438)} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {/* {[
                        {
                            'deviceName': 'device 01',
                            'deviceID': 'deviceID',
                            'position': new LatLng(50.1432, 9.1432)
                        },
                        {
                            'deviceName': 'device 02',
                            'deviceID': 'deviceID 2',
                            'position': new LatLng(50.1402, 9.1452)
                        }
                    ].map((d, idx) => CreateMarkerForDevice(d.deviceName, d.deviceID, d.position, idx))} */}
                    {
                        // Object.entries(props.Devices).map(([deviceID, device], idx) => (
                        //     // <div className="asdf">{deviceID}</div>
                        //     CreateMarkerForDevice((device as Device).object_id, deviceID, props.Devices.get(deviceID)!.getPosition()!, idx)
                        // ))
                        Array.from(props.Devices).map(([deviceID, device], idx) => {
                            if (device.getPosition() === undefined) {
                                return <div key={idx}></div>
                            }
                            if (!homePosition) {
                                setHomePosition(device.getPosition()!);
                            }
                            return CreateMarkerForDevice(device, idx)
                            // return CreateMarkerForDevice(device.device_type, device.device_id, deviceID.toString(), device.getPosition()!, idx)
                            // return <div key={idx}>
                            // {CreateMarkerForDevice(device, idx)}
                            // </div>
                        })
                    }


                </MapContainer>
            </Collapse >
        </>
    )
}
