import { Device } from "../models/devices/device";
import { NBX } from "../models/devices/nbx";
import { Notification } from "../models/notifications";
import { UBX } from "../models/devices/ubx";
import { Organisation } from "../models/organisation";





export type DashboardState = {
    // connection
    websocket: WebSocket | null,
    websocketMessages: string[],
    errorMessage: string;

    // user and organisation
    username: string,
    userID: string,
    organisations: Organisation[],
    userEMail: string,

    // device related
    notifications: Notification[],
    // deviceNotification: DeviceNotification<any>[],

    /**
     * Map <objectID, Device>
     * where the objectID is the databaseID
     */
    devices: Map<string, Device>,
}

export const InitialDashboardState: DashboardState = {
    websocket: null,
    // jwtToken: '',
    websocketMessages: [
        'message 1',
        'message 2'
    ],
    errorMessage: '',

    username: '',
    userID: '',
    organisations: [],
    userEMail: '',


    devices: new Map<string, NBX | UBX>(),
    notifications: [],
    // deviceNotification: [],
}


// enum DashboardStateActionType {
//     // State
//     /**
//      * Reset all data
//      */
//     ResetDashboardState,


//     // Auth / Login
//     /**
//      * Set the error message shown on the login page
//      */
//     SetLoginErrorMessage,

//     /**
//      * Login
//      */
//     Login,

//     /**
//      * Set credentials used for login
//      */
//     SetCredentials,

//     /**
//      * Set the username after succesful login
//      */
//     SetUsername,

//     /**
//      * Select, which organisation to use for the user
//      */
//     SelectOrganisation,

//     // Websocket
//     SetWebsocket,
//     UnsetWebsocket,
//     CloseWebsocket,
//     HandleWebsocketMessage,

//     // Devices
//     // UpdateDeviceName,
//     // SendDeviceCommand,

//     // Notifications
//     // UpdateNotification,
//     // TestNotification,
//     // QueryNotifications,

//     // Settings
//     // UpdateSettings
// }

export interface DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState,
}

export class ResetDashboardState implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        return InitialDashboardState
    };
}







// export class SendDeviceCommandAction implements DashboardStateAction {
//     type: DashboardStateActionType = DashboardStateActionType.SendDeviceCommand;
//     updateState: (state: DashboardState) => DashboardState = (s) => s;
//     deviceID: string;
//     commandString: string;
//     constructor(deviceID: string, commandString: string) {
//         this.deviceID = deviceID;
//         this.commandString = commandString;
//     }
// }

// export class UpdateDeviceNameAction implements DashboardStateAction {
//     type: DashboardStateActionType = DashboardStateActionType.UpdateDeviceName;
//     updateState: (state: DashboardState) => DashboardState = (s) => s;
//     deviceID: string;
//     newDeviceName: string;
//     constructor(deviceID: string, newDeviceName: string) {
//         this.deviceID = deviceID;
//         this.newDeviceName = newDeviceName;
//     }
// }


export const reducer: (state: DashboardState, action: DashboardStateAction) => DashboardState = (state, action) => {
    return action.updateState(state);

    // switch (action.type) {
    //     case DashboardStateActionType.ResetDashboardState:
    //         return InitialDashboardState;


    //     case DashboardStateActionType.CloseWebsocket:
    //         state.websocket?.close();
    //         return {
    //             ...state,
    //             websocket: null,
    //             userEMail: '',
    //             userID: '',
    //             username: '',
    //             userrole: UserRole.invalid,
    //             credentials: {
    //                 password: '',
    //                 username: ''
    //             },
    //             organisationID: []
    //         }

    //     case DashboardStateActionType.SetCredentials:
    //         return {
    //             ...state,
    //             credentials: {
    //                 password: (action as SetCredentialsAction).password,
    //                 username: (action as SetCredentialsAction).username,
    //             }
    //         }

    //     case DashboardStateActionType.SelectOrganisation:
    //         // TODO(flo, IMPL, PRIO1) implement me
    //         return {
    //             ...state,
    //             organisationID: (action as SelectOrganisationAction).organisationID,
    //         }

    //     case DashboardStateActionType.UpdateNotification:
    //         log_text(action, DebugLevels.ERROR);
    //         state.websocket?.send(JSON.stringify({
    //             'type': 'request',
    //             'topic': 'update_notification',
    //             'value': (action as UpdateNotificationAction).notification
    //         }))
    //         return {
    //             ...state
    //         }

    //     case DashboardStateActionType.SendDeviceCommand:
    //         log_text(action, DebugLevels.ERROR);
    //         state.websocket?.send(JSON.stringify({
    //             'type': 'request',
    //             'topic': 'send_command',
    //             'value': {
    //                 'device_id': (action as SendDeviceCommandAction).deviceID,
    //                 'command': (action as SendDeviceCommandAction).commandString,
    //             }
    //         }))
    //         return {
    //             ...state
    //         }

    //     case DashboardStateActionType.UpdateDeviceName:
    //         state.websocket?.send(JSON.stringify({
    //             'type': 'request',
    //             'topic': 'update_device_name',
    //             'value': {
    //                 'device_id': (action as UpdateDeviceNameAction).deviceID,
    //                 'new_device_name': (action as UpdateDeviceNameAction).newDeviceName,
    //             }
    //         }))
    //         return {
    //             ...state
    //         }

    //     case DashboardStateActionType.QueryNotifications:
    //         state.websocket?.send(JSON.stringify({
    //             'type': 'request',
    //             'topic': 'notifications',
    //             // [ ] change type to the correct one
    //             'value': (action as TestNotificationAction).notificationID,
    //         }));
    //         return {
    //             ...state
    //         }

    //     case DashboardStateActionType.TestNotification:
    //         state.websocket?.send(JSON.stringify({
    //             'type': 'request',
    //             'topic': 'test_notification',
    //             'value': (action as TestNotificationAction).notificationID,
    //         }));
    //         return {
    //             ...state
    //         }




    //             default:
    //                 return {
    //                     ...state,
    //                     websocketMessages: [...state.websocketMessages, (action as HandleWebsocketMessage).message]
    //                 }

    //         }
    // }
}

// function updateNotification(state: DashboardState, notification: any) {
//     switch (notification['type']) {
//         case 'telegram':
//             log_text(notification);
//             let telegramNotification: TelegramNotification = new TelegramNotification(
//                 {
//                     // basic information
//                     notificationID: notification['notification_id'],
//                     username: notification['username'],
//                     databaseID: notification['database_id'],

//                     // telegram information
//                     apiToken: notification['api_token'],
//                     channelID: notification['channel_id'],
//                 }
//             );
//             state.notifications.push(telegramNotification);
//             break;

//         default:
//             break;
//     }
// }

// function updateDevice(state: DashboardState, device: any) {
//     switch (device['device_type']) {
//         case 'nbx':
//             let nbx: NBX = new NBX(
//                 device['device_name'],
//                 device['device_id'],
//                 {
//                     lastUpdate: Date.parse(device['last_update']),
//                     VDD: roundVoltage(device['vdd']),
//                     VIN0: roundVoltage(device['vin0']),
//                     VIN1: roundVoltage(device['vin1']),
//                     VIN2: roundVoltage(device['vin2']),

//                     DIN2: device['din2'],
//                     DIN3: device['din3'],

//                     // FUG
//                     RSSI: device['tetra_rssi'],
//                     CellState: device['tetra_cell_state'],
//                     latitude: device['pos_latitude'],
//                     longitude: device['pos_longitude']
//                 },
//             )
//             // devices.push(nbx);
//             state.devices.set(device['device_id'], nbx);
//             break;
//         case 'ubx':
//             let ubx: UBX = new UBX(
//                 device['hostname'],
//                 device['device_id'],
//                 {
//                     softwareVersion: device['software_version'],
//                     // deviceState: DeviceStateFromNumber(device['device_state']),
//                     // check if the last update is more than 10 days (?) ago
//                     lastUpdate: Date.parse(device['last_update']),

//                     // Voltages
//                     VDD: roundVoltage(device['vdd']),
//                     VIN0: roundVoltage(device['vin0']),
//                     VIN1: roundVoltage(device['vin1']),

//                     // Digital IO
//                     din1: device['din_1'] === 1 ? true : false,
//                     din2: device['din_2'] === 1 ? true : false,
//                     dout1: device['dout_1'] === 1 ? true : false,
//                     dout2: device['dout_2'] === 1 ? true : false,

//                     // FuG 
//                     fug_state: device['fug_state'],
//                     fug_mode: device['fug_mode'],
//                     fug_vol: device['fug_vol'],
//                     folder_name: device['folder_name'],
//                     fug_manufacturer: device['fug_manufacturer'],

//                     c_reg_state: device['tetra_creg_state'],
//                     cell_id: device['tetra_cell_id'],
//                     gssi_name: device['tetra_group'],
//                     tetra_gssi: device['tetra_gssi'],
//                     issi: device['tetra_issi'],
//                     rssi: device['tetra_rssi'],

//                     tetra_status: device['tetra_status'],
//                     tetra_net: device['tetra_net'],
//                     tetra_opta: device['tetra_opta'],

//                     // GSM
//                     gsm_carrier: device['gsm_carrier'],
//                     gsm_iccid: device['gsm_iccid'],
//                     gsm_signal_strength: device['gsm_signal_strength'],

//                     // Licences
//                     bluetoothLicence: device['licence_bluetooth'] === 1 ? true : false,
//                     dataLicence: device['licence_data'] === 1 ? true : false,
//                     navigationLicence: device['licence_navigation'] === 1 ? true : false,
//                 }

//             );
//             // devices.push(ubx);
//             state.devices.set(device['device_id'], ubx);
//             break;

//         default:
//             break;
//     }
// }