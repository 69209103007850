import NBXWidget from "../../widgets/dashboard/nbx";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import UBXWidget from "../../widgets/dashboard/ubx";
import { UBX } from "../../models/devices/ubx";
import { NBX } from "../../models/devices/nbx";
import { log_table } from "../../log";
import { Device } from "../../models/devices/device";
import { Sirene } from "../../models/devices/sirene";
import SirenenWidget from "../../widgets/dashboard/sirene";

// TODO(flo,BUG,PRIO1) the tiles are created with an invalid deviceID, so clicking them leads to an invalid site
function Devices() {
    const { dashboardState } = useDashboardState();
    // const [devices, setDevices] = useState<Device[]>([]);

    let deviceElements: JSX.Element[] = [];

    let devices: (Device)[] = [...dashboardState.devices.values()];
    // devices.sort((a, b) => a.getState() - b.getState());

    // devices contains a list of nbx or ubx entries
    // dashboardState.devices.forEach((device, id) => {
    devices.forEach((device, id) => {
        if (device instanceof UBX) {
            deviceElements.push(<UBXWidget key={id} model={device} />);
            return
        }
        if (device instanceof NBX) {
            deviceElements.push(<NBXWidget key={id} model={device} />);
            return
        }
        if (device instanceof Sirene) {
            deviceElements.push(<SirenenWidget key={id} model={device} />);
            return
        }
        deviceElements.push(<p>Ger&auml;t konnte nicht geladen werden</p>);
    });

    log_table(deviceElements);


    return (
        <div className="dashboard-body">
            <h1>Geräte:</h1>
            <div className="devices-list">
                {deviceElements}
            </div>
        </div>
    )
}

export default Devices;