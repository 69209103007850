import { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NBX } from "../../models/devices/nbx";
import { UBX } from "../../models/devices/ubx";
import { Device, DeviceState } from "../../models/devices/device";
import { DeviceStateOverview } from "../../widgets/dashboard/device_state_overview";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { CVM } from "../../models/devices/cvm";
import { Sirene } from "../../models/devices/sirene";

export function MultiDeviceStateOverview(props: { devices: Map<string, Device> }): JSX.Element {
    const [open, setOpen] = useState<boolean>(true);
    const { dashboardState } = useDashboardState();

    let nbx: NBX[] = [];
    let ubx: UBX[] = [];
    let cvm: CVM[] = [];
    let sirenen: Sirene[] = [];

    dashboardState.devices.forEach((device) => {
        if (device instanceof NBX) nbx.push(device);
        if (device instanceof UBX) ubx.push(device);
        if (device instanceof CVM) cvm.push(device);
        if (device instanceof Sirene) sirenen.push(device);
    });

    return (
        <>
            <h2>&Uuml;bersicht <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton> </h2>
            <Collapse in={open}>
                <div className="devices-state-overview">
                    {
                        nbx.length >= 1 ? <DeviceStateOverview title='NBX' data={[
                            nbx.filter((device) => device.getState() === DeviceState.OK).length,
                            nbx.filter((device) => device.getState() === DeviceState.WARNING).length,
                            nbx.filter((device) => device.getState() === DeviceState.ALARM).length,
                            nbx.filter((device) => device.getState() === DeviceState.NO_STATE).length,
                        ]} /> : <></>
                    }
                    {
                        ubx.length >= 1 ? <DeviceStateOverview title='UBX' data={[
                            ubx.filter((device) => device.getState() === DeviceState.OK).length,
                            ubx.filter((device) => device.getState() === DeviceState.WARNING).length,
                            ubx.filter((device) => device.getState() === DeviceState.ALARM).length,
                            ubx.filter((device) => device.getState() === DeviceState.NO_STATE).length,
                        ]} /> : <></>
                    }
                    {
                        cvm.length >= 1 ? <DeviceStateOverview title='CVM' data={[
                            cvm.filter((device) => device.getState() === DeviceState.OK).length,
                            cvm.filter((device) => device.getState() === DeviceState.WARNING).length,
                            cvm.filter((device) => device.getState() === DeviceState.ALARM).length,
                            cvm.filter((device) => device.getState() === DeviceState.NO_STATE).length,
                        ]} />
                            : <></>
                    }
                    {
                        sirenen.length >= 1 ? <DeviceStateOverview title='Sirenen' data={[
                            sirenen.filter((device) => device.getState() === DeviceState.OK).length,
                            sirenen.filter((device) => device.getState() === DeviceState.WARNING).length,
                            sirenen.filter((device) => device.getState() === DeviceState.ALARM).length,
                            sirenen.filter((device) => device.getState() === DeviceState.NO_STATE).length,
                        ]} />
                            : <></>
                    }
                    {/* <DeviceStateOverview title='Sirenen' data={null} />
                    <DeviceStateOverview title='CVM-Module' data={[0, 0, 3, 14]} /> */}
                </div>
            </Collapse>
        </>
    )
}