// import { BooleanDeviceNotification, DeviceNotification, NumberDeviceNotification, StringDeviceNotification } from "./deviceNotification";

/**
 * The DeviceProperties change and the user can set up notifications
 */
export abstract class DeviceProperty<T> {
    name: string
    value: T;
    // abstract notification: DeviceNotification<T>;

    constructor(name: string, value: T) {
        this.name = name;
        this.value = value;
    }


    setValue(value: T | undefined) {
        if (value) {
            this.value = value;
        }
    }

    abstract representation(): string;
}


// abstract class 

export class BoolDeviceProperty extends DeviceProperty<boolean> {
    representation(): string {
        if (this.value === undefined) return 'unbekannt';
        // return this.value === true ? 'ja' : 'nein';
        return this.value === true ? 'an' : 'aus';
    }
    // notification: DeviceNotification<boolean> = new BooleanDeviceNotification();

    setValue(value?: boolean | undefined): void {
        if (value === undefined) return;
        if (value === this.value) return;
        this.value = value;
    }

    constructor(name: string, initValue: boolean = false) {
        super(name, initValue);
    }
}

export class StringDeviceProperty extends DeviceProperty<string> {
    representation(): string { return this.value };
    // notification: DeviceNotification<string>;

    constructor(name: string, initValue: string = '') {
        super(name, initValue);
        // this.notification = new StringDeviceNotification();
    }
}

export class NumberDeviceProperty extends DeviceProperty<number> {
    representation(): string {
        return this.value.toFixed(2);
    }
    // notification: DeviceNotification<number>;

    constructor(name: string, initValue: number = 0) {
        super(name, initValue);
        // let notification = new NumberDeviceNotification();
        // notification.lowerWarningValue = 11
        // notification.lowerWarningNotification = new EmptyNotification();
        // notification.upperAlertValue = 20
        // notification.upperAlertNotification = new EmptyNotification();

        // this.notification = notification;
    }
}