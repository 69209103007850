import { LatLng } from "leaflet";

export enum DeviceType { nbx, ubx, cvm, sirene, unknown };

export function deviceTypToString(deviceType: DeviceType): string {
    switch (deviceType) {
        case DeviceType.nbx:
            return 'NBX'
        case DeviceType.ubx:
            return 'UBX'
        case DeviceType.cvm:
            return 'CVM'
        case DeviceType.sirene:
            return 'Sirene'
        default:
            return 'Unbekannt'
    }
}

export function intToDeviceType(inp: number): DeviceType {
    switch (inp) {
        case 0:
            return DeviceType.ubx;
        case 1:
            return DeviceType.nbx;
        case 2:
            return DeviceType.cvm;
        case 3:
            return DeviceType.sirene;
        default:
            return DeviceType.unknown
    }
}

export enum DeviceState {
    /** At least one datapoint is in alarm region */
    ALARM = 0,
    /** At least one datapoint is in warning region, but none is in alarm region */
    WARNING = 1,
    /** No Datapoint is in warning or alarm region */
    OK = 2,
    /** The date, when the device was last seen, is above a threshold */
    DATA_EXPIRED = 3,
    /** default state */
    NO_STATE = -1,
};

export function mapDeviceStateToColor(deviceState: DeviceState): string {
    switch (deviceState) {
        case DeviceState.OK:
            return "green"
        case DeviceState.WARNING:
            return "orange"
        case DeviceState.ALARM:
            return "red"
        default:
            return "black"
    }
}

// export function DeviceStateFromNumber(state: number): DeviceState {
//     switch (state) {
//         case 0:
//         default:
//             return DeviceState.NO_STATE;
//         case 1:
//             return DeviceState.ALARM;
//         case 2:
//             return DeviceState.WARNING;
//         case 3:
//             return DeviceState.OK;
//         case 4:
//             return DeviceState.DATA_EXPIRED;
//     }
// }

export abstract class Device {
    device_type: DeviceType;
    device_id: string;
    // device_name: string;
    // device_id: string;
    object_id: string;
    // state: DeviceState = DeviceState.OK;
    lastUpdate: number | undefined;

    abstract getPosition(): LatLng | undefined;
    abstract updateData(key: string, value: any): void;

    // updateState(state: DeviceState) {
    //     this.state = state;
    // }

    abstract getState(): DeviceState;

    // getColorState(): string {
    //     switch (this.getState()) {
    //         case DeviceState.OK:
    //             return 'green';
    //         case DeviceState.WARNING:
    //             return 'orange';
    //         case DeviceState.ALARM:
    //             return 'red';
    //         default:
    //             return 'gray';
    //     }
    // }

    constructor(device_id: string, type: DeviceType, object_id: string, lastUpdate: number) {
        this.device_id = device_id;
        this.device_type = type;
        this.object_id = object_id;
        // if (params.state !== undefined) this.state = params.state;
        this.lastUpdate = lastUpdate;
    }

}